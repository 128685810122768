package com.rdapps.weddinginvitation.theme

import androidx.compose.ui.graphics.Color

internal val md_theme_dark_primary = Color(0xFFbb1d24)
internal val md_theme_dark_onPrimary = Color(0xFFFFE264)
internal val md_theme_dark_primaryContainer = Color(0xFFbb1d24)
internal val md_theme_dark_onPrimaryContainer = Color(0xFFFFE264)
internal val md_theme_dark_secondary = Color(0xFFA21D24)
internal val md_theme_dark_onSecondary = Color(0xFFFFE264)
internal val md_theme_dark_secondaryContainer = Color(0xFFFFFFFF)
internal val md_theme_dark_onSecondaryContainer = Color(0xFFbb1d24)
internal val md_theme_dark_tertiary = Color(0xFFFFB77C)
internal val md_theme_dark_onTertiary = Color(0xFF4D2700)
internal val md_theme_dark_tertiaryContainer = Color(0xFF6D3900)
internal val md_theme_dark_onTertiaryContainer = Color(0xFFFFDCC2)
internal val md_theme_dark_error = Color(0xFFFFB4AB)
internal val md_theme_dark_errorContainer = Color(0xFF93000A)
internal val md_theme_dark_onError = Color(0xFF690005)
internal val md_theme_dark_onErrorContainer = Color(0xFFFFDAD6)
internal val md_theme_dark_background = Color(0xFFbb1d24)
internal val md_theme_dark_onBackground = Color(0xFFFFE264)
internal val md_theme_dark_surface = Color(0xFFbb1d24)
internal val md_theme_dark_onSurface = Color(0xFFFFE264)
internal val md_theme_dark_surfaceVariant = Color(0xFF3F484B)
internal val md_theme_dark_onSurfaceVariant = Color(0xFFBFC8CB)
internal val md_theme_dark_outline = Color(0xFFFFE264)
internal val md_theme_dark_inverseOnSurface = Color(0xFF221B00)
internal val md_theme_dark_inverseSurface = Color(0xFFFFE264)
internal val md_theme_dark_inversePrimary = Color(0xFF00687A)
internal val md_theme_dark_shadow = Color(0xFF000000)
internal val md_theme_dark_surfaceTint = Color(0xFFFFE264)
internal val md_theme_dark_outlineVariant = Color(0xFFFFE264)
internal val md_theme_dark_scrim = Color(0xFF000000)


internal val seed = Color(0xFF2C3639)
