@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package wedding_invitation.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val Allura_Regular: FontResource by 
      lazy { init_Allura_Regular() }

  public val Mogra_Regular: FontResource by 
      lazy { init_Mogra_Regular() }

  public val Satisfy_Regular: FontResource by 
      lazy { init_Satisfy_Regular() }

  public val Shrikhand_Regular: FontResource by 
      lazy { init_Shrikhand_Regular() }
}

internal val Res.font.Allura_Regular: FontResource
  get() = CommonMainFont0.Allura_Regular

private fun init_Allura_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Allura_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/font/Allura-Regular.ttf", -1, -1),
    )
)

internal val Res.font.Mogra_Regular: FontResource
  get() = CommonMainFont0.Mogra_Regular

private fun init_Mogra_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Mogra_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/font/Mogra-Regular.ttf", -1, -1),
    )
)

internal val Res.font.Satisfy_Regular: FontResource
  get() = CommonMainFont0.Satisfy_Regular

private fun init_Satisfy_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Satisfy_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/font/Satisfy-Regular.ttf", -1, -1),
    )
)

internal val Res.font.Shrikhand_Regular: FontResource
  get() = CommonMainFont0.Shrikhand_Regular

private fun init_Shrikhand_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Shrikhand_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/font/Shrikhand-Regular.ttf", -1, -1),
    )
)
