@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package wedding_invitation.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val design: DrawableResource by 
      lazy { init_design() }

  public val external_link: DrawableResource by 
      lazy { init_external_link() }

  public val ganesh: DrawableResource by 
      lazy { init_ganesh() }

  public val om_symbol: DrawableResource by 
      lazy { init_om_symbol() }

  public val party_horn: DrawableResource by 
      lazy { init_party_horn() }

  public val red_bg_1: DrawableResource by 
      lazy { init_red_bg_1() }

  public val red_bg_2: DrawableResource by 
      lazy { init_red_bg_2() }

  public val round_article_24: DrawableResource by 
      lazy { init_round_article_24() }

  public val round_calendar_month_24: DrawableResource by 
      lazy { init_round_calendar_month_24() }

  public val round_call_24: DrawableResource by 
      lazy { init_round_call_24() }

  public val round_location_on_24: DrawableResource by 
      lazy { init_round_location_on_24() }

  public val round_time_filled_24: DrawableResource by 
      lazy { init_round_time_filled_24() }
}

internal val Res.drawable.design: DrawableResource
  get() = CommonMainDrawable0.design

private fun init_design(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:design",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/design.webp", -1, -1),
    )
)

internal val Res.drawable.external_link: DrawableResource
  get() = CommonMainDrawable0.external_link

private fun init_external_link(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:external_link",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/external_link.xml", -1, -1),
    )
)

internal val Res.drawable.ganesh: DrawableResource
  get() = CommonMainDrawable0.ganesh

private fun init_ganesh(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ganesh",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/ganesh.xml", -1, -1),
    )
)

internal val Res.drawable.om_symbol: DrawableResource
  get() = CommonMainDrawable0.om_symbol

private fun init_om_symbol(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:om_symbol",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/om_symbol.xml", -1, -1),
    )
)

internal val Res.drawable.party_horn: DrawableResource
  get() = CommonMainDrawable0.party_horn

private fun init_party_horn(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:party_horn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/party_horn.xml", -1, -1),
    )
)

internal val Res.drawable.red_bg_1: DrawableResource
  get() = CommonMainDrawable0.red_bg_1

private fun init_red_bg_1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:red_bg_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/red_bg_1.webp", -1, -1),
    )
)

internal val Res.drawable.red_bg_2: DrawableResource
  get() = CommonMainDrawable0.red_bg_2

private fun init_red_bg_2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:red_bg_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/red_bg_2.webp", -1, -1),
    )
)

internal val Res.drawable.round_article_24: DrawableResource
  get() = CommonMainDrawable0.round_article_24

private fun init_round_article_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_article_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/round_article_24.xml", -1, -1),
    )
)

internal val Res.drawable.round_calendar_month_24: DrawableResource
  get() = CommonMainDrawable0.round_calendar_month_24

private fun init_round_calendar_month_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_calendar_month_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/round_calendar_month_24.xml", -1, -1),
    )
)

internal val Res.drawable.round_call_24: DrawableResource
  get() = CommonMainDrawable0.round_call_24

private fun init_round_call_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_call_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/round_call_24.xml", -1, -1),
    )
)

internal val Res.drawable.round_location_on_24: DrawableResource
  get() = CommonMainDrawable0.round_location_on_24

private fun init_round_location_on_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_location_on_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/round_location_on_24.xml", -1, -1),
    )
)

internal val Res.drawable.round_time_filled_24: DrawableResource
  get() = CommonMainDrawable0.round_time_filled_24

private fun init_round_time_filled_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_time_filled_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/drawable/round_time_filled_24.xml", -1, -1),
    )
)
