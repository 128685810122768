@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package wedding_invitation.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val calendar_event_url: StringResource by 
      lazy { init_calendar_event_url() }

  public val ip_info_token: StringResource by 
      lazy { init_ip_info_token() }

  public val open_github: StringResource by 
      lazy { init_open_github() }

  public val sb_key: StringResource by 
      lazy { init_sb_key() }

  public val sb_url: StringResource by 
      lazy { init_sb_url() }

  public val stay_url: StringResource by 
      lazy { init_stay_url() }

  public val theme: StringResource by 
      lazy { init_theme() }

  public val venue_url: StringResource by 
      lazy { init_venue_url() }

  public val welcome_text: StringResource by 
      lazy { init_welcome_text() }

  public val welcome_text_without_name: StringResource by 
      lazy { init_welcome_text_without_name() }
}

internal val Res.string.calendar_event_url: StringResource
  get() = CommonMainString0.calendar_event_url

private fun init_calendar_event_url(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:calendar_event_url", "calendar_event_url",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/strings.commonMain.cvr",
    10, 254),
    )
)

internal val Res.string.ip_info_token: StringResource
  get() = CommonMainString0.ip_info_token

private fun init_ip_info_token(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ip_info_token", "ip_info_token",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/keys.commonMain.cvr",
    10, 41),
    )
)

internal val Res.string.open_github: StringResource
  get() = CommonMainString0.open_github

private fun init_open_github(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:open_github", "open_github",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/strings.commonMain.cvr",
    265, 35),
    )
)

internal val Res.string.sb_key: StringResource
  get() = CommonMainString0.sb_key

private fun init_sb_key(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sb_key", "sb_key",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/keys.commonMain.cvr",
    52, 294),
    )
)

internal val Res.string.sb_url: StringResource
  get() = CommonMainString0.sb_url

private fun init_sb_url(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sb_url", "sb_url",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/keys.commonMain.cvr",
    347, 70),
    )
)

internal val Res.string.stay_url: StringResource
  get() = CommonMainString0.stay_url

private fun init_stay_url(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:stay_url", "stay_url",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/strings.commonMain.cvr",
    301, 300),
    )
)

internal val Res.string.theme: StringResource
  get() = CommonMainString0.theme

private fun init_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:theme", "theme",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/strings.commonMain.cvr",
    602, 21),
    )
)

internal val Res.string.venue_url: StringResource
  get() = CommonMainString0.venue_url

private fun init_venue_url(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:venue_url", "venue_url",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/strings.commonMain.cvr",
    624, 469),
    )
)

internal val Res.string.welcome_text: StringResource
  get() = CommonMainString0.welcome_text

private fun init_welcome_text(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome_text", "welcome_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/strings.commonMain.cvr",
    1212, 104),
    )
)

internal val Res.string.welcome_text_without_name: StringResource
  get() = CommonMainString0.welcome_text_without_name

private fun init_welcome_text_without_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:welcome_text_without_name", "welcome_text_without_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/wedding_invitation.composeapp.generated.resources/values/strings.commonMain.cvr",
    1094, 117),
    )
)
